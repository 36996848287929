.label{
  display:flex;
   flex-direction:column;
   width:23.3px;
   cursor:pointer;
   -moz-transform: scale(-1, 1);
   -webkit-transform: scale(-1, 1);
   -o-transform: scale(-1, 1);
   -ms-transform: scale(-1, 1);
   transform: scale(-1, 1);
}

.label > span{
   background: #fff;
   border-radius:10px;
   height:2.333px;
   margin: 2.3333px 0;
   transition: .3s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.label > span:nth-of-type(1){
   width:50%;
}

.label > span:nth-of-type(2){
   width:100%;
}

.label > span:nth-of-type(3){
   width:75%;
}

.label > input[type="checkbox"]{
   display:none;
}

.label > input[type="checkbox"]:checked ~ span:nth-of-type(1){
   transform-origin:bottom;
   transform:rotatez(45deg) translate(4px,0px)
}

.label > input[type="checkbox"]:checked ~ span:nth-of-type(2){
   transform-origin:top;
   transform:rotatez(-45deg)
}

.label > input[type="checkbox"]:checked ~ span:nth-of-type(3){
   transform-origin:bottom;
   width:50%;
   transform: translate(10px,-3.3px) rotatez(45deg);
}